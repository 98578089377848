var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h6 border-dark" }, [
    _vm.load
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "text-center text-dark my-2 col" },
            [
              _c("b-spinner", { staticClass: "align-middle" }),
              _c("strong", [_vm._v("Carregando...")]),
            ],
            1
          ),
        ])
      : _c(
          "div",
          [
            _c("dl", { staticClass: "row" }, [
              _c("dt", { staticClass: "col-sm-3 font-weight-bolder" }, [
                _vm._v(_vm._s(_vm.$t("payments.labels.startDate")) + ":"),
              ]),
              _c("dd", { staticClass: "col-sm-9" }, [
                _vm._v(
                  _vm._s(_vm._f("moment")(_vm.startDate, "DD/MM/YYYY")) + " "
                ),
              ]),
            ]),
            _c("hr"),
            _c("dl", { staticClass: "row" }, [
              _c("dt", { staticClass: "col-sm-3 font-weight-bolder" }, [
                _vm._v(
                  _vm._s(_vm.$t("payments.labels.totalInstallments")) + ":"
                ),
              ]),
              _c("dd", { staticClass: "col-sm-9" }, [
                _vm._v(_vm._s(_vm.installments) + "x"),
              ]),
            ]),
            _c("hr"),
            _c("dl", { staticClass: "row" }, [
              _c("dt", { staticClass: "col-sm-3 font-weight-bolder" }, [
                _vm._v(_vm._s(_vm.$t("payments.labels.paymentMethod")) + ":"),
              ]),
              _c("dd", { staticClass: "col-sm-9" }, [
                _vm._v(" " + _vm._s(_vm.formatMethodPayment)),
              ]),
            ]),
            _c("hr"),
            _c("dl", { staticClass: "row" }, [
              _c(
                "dt",
                { staticClass: "col-sm-3 text-hei font-weight-bolder" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("payments.labels.totalNegotiationTransaction")
                    ) + ":"
                  ),
                ]
              ),
              _c("dd", { staticClass: "col-sm-9" }, [
                _vm._v(
                  _vm._s(
                    _vm.setPriceFormat(
                      _vm.$t("payments.labels.currency"),
                      _vm.amount_reference
                    )
                  )
                ),
              ]),
            ]),
            _c("hr"),
            _c("dl", { staticClass: "row" }, [
              _c(
                "dt",
                { staticClass: "col-sm-3 text-hei font-weight-bolder" },
                [_vm._v(_vm._s(_vm.$t("payments.labels.totalInterest")) + ":")]
              ),
              _c("dd", { staticClass: "col-sm-9" }, [
                _vm._v(
                  _vm._s(
                    _vm.setPriceFormat(
                      _vm.$t("payments.labels.currency"),
                      _vm.interestTotal
                    )
                  )
                ),
              ]),
            ]),
            _c("hr", { staticClass: "mb-0" }),
            _c("dl", { staticClass: "row bg-light py-3 mt-0" }, [
              _c(
                "dt",
                { staticClass: "col-sm-3 text-hei font-weight-bolder" },
                [
                  _vm._v(
                    _vm._s(_vm.$t("payments.labels.totalNegotiation")) + ":"
                  ),
                ]
              ),
              _c(
                "dd",
                { staticClass: "col-sm-9 text-success font-weight-bolder" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.setPriceFormat(
                        _vm.$t("payments.labels.currency"),
                        _vm.amount
                      )
                    )
                  ),
                ]
              ),
            ]),
            _vm._t("default"),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }