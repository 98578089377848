<template>
  <div class="h6 border-dark">
    <div class="row" v-if="load" >
      <div class="text-center text-dark my-2 col">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Carregando...</strong>
      </div>
    </div>
    <div v-else>
      <dl class="row">
        <dt class="col-sm-3 font-weight-bolder">{{ $t('payments.labels.startDate') }}:</dt>
        <dd class="col-sm-9">{{ startDate | moment('DD/MM/YYYY') }} </dd>
      </dl>
      <hr>
      <dl class="row">
        <dt class="col-sm-3 font-weight-bolder">{{ $t('payments.labels.totalInstallments') }}:</dt>
        <dd class="col-sm-9">{{ installments }}x</dd>
      </dl>
      <hr>
      <dl class="row">
        <dt class="col-sm-3 font-weight-bolder">{{ $t('payments.labels.paymentMethod') }}:</dt>
        <dd class="col-sm-9"> {{ formatMethodPayment }}</dd>
      </dl>
      <hr>
      <dl class="row">
        <dt class="col-sm-3 text-hei font-weight-bolder">{{ $t('payments.labels.totalNegotiationTransaction') }}:</dt>
        <dd class="col-sm-9">{{ setPriceFormat($t('payments.labels.currency'), amount_reference) }}</dd>
      </dl>
      <hr>
      <dl class="row">
        <dt class="col-sm-3 text-hei font-weight-bolder">{{ $t('payments.labels.totalInterest') }}:</dt>
        <dd class="col-sm-9">{{ setPriceFormat($t('payments.labels.currency'), interestTotal) }}</dd>
      </dl>
      <hr class="mb-0">
      <dl class="row bg-light py-3 mt-0">
        <dt class="col-sm-3 text-hei font-weight-bolder">{{ $t('payments.labels.totalNegotiation') }}:</dt>
        <dd class="col-sm-9 text-success font-weight-bolder">{{ setPriceFormat($t('payments.labels.currency'), amount) }}</dd>
      </dl>
      <slot />
    </div>
  </div>
</template>

<script>
import NEGOTIATION_SIMULATION from '@graphql/negotiation/queries/getNegotiationsSimulation.gql';
import { makeQuery } from '@graphql/middleware'
import { formatPrice } from '@utils/numbers';
export default {
  name: 'DriverNegotiationResume',
  props: {
    transactions: {
      type: Array,
      required: true
    },
    focused: {
      type: Boolean
    },
    startDate: {
      type: String,
      default: ''
    },
    paymentMethodType: {
      type: String,
      required: true
    },
    installments: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      amount: '',
      amount_reference: '',
      interestTotal: '',
      load: true,
    }
  },
  computed: {
    formatMethodPayment: function () {
      switch (this.paymentMethodType) {
        case 'boleto':
          return this.$t('payments.enumMethod.billet');
        case 'oxxo':
          return this.$t('payments.enumMethod.oxxo');
        case 'credit_card':
          return this.$t('payments.enumMethod.creditCard');
        case 'pix':
          return this.$t('payments.enumMethod.pix');
        default:
          return ''
      }
    }
  },
  watch:{
    focused: function (val) {
      if(val) {
        this.getNegotiationSimulation()
      }
    },
  },
  methods: {
    getNegotiationSimulation () {
      this.load = true
      makeQuery(
        NEGOTIATION_SIMULATION,
        { 
          invoices: this.transactions.map((item) => item.invoice)
        }
      )
      .then((res) => {
        let data = res.data.getNegotiationSimulation
        this.amount = data.amount;
        this.amount_reference = data.amount_reference
        this.interestTotal = data.taxes?.total
        this.load = false;
        this.notifyLoad(data);
      })
    },
    notifyLoad (data) {
      this.$emit('load', {
        amount: data.amount,
        amount_reference: data.amount_reference,
        interestTotal: data.taxes?.total
      });
    },
    setPriceFormat(_currency, _value) {
      return formatPrice(_currency, _value);
    },
  }
}
</script>